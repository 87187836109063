<template>
  <div class="company_info">
    <div class="name_wrapper">
      <div class="name">{{ details.name }}</div>
      <el-popover
        placement="bottom"
        title=""
        width="200"
        trigger="hover"
        :content="details.nameUsed"
      >
        <div class="cursorP tag_style" slot="reference">曾用名</div>
      </el-popover>
    </div>

    <div class="bgf5faff pd20 c666">
      <el-row :gutter="20" class="mb15">
        <el-col class="flex alc" :span="12">
          <div class="label">成立日期：</div>
          <div class="value singleLine">{{ details.establishmentTime }}</div>
        </el-col>

        <el-col class="flex alc" :span="12">
          <div class="label">注册资金：</div>
          <div class="value singleLine">{{ details.regCost }}</div>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="mb15">
        <el-col class="flex alc" :span="12">
          <div class="label">法人代表：</div>
          <div class="value singleLine">{{ details.legalPerson }}</div>
        </el-col>

        <el-col class="flex alc" :span="12">
          <div class="label">注册地址：</div>
          <div class="value singleLine">
            {{ details.regAddress }}
          </div>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col class="flex" :span="24">
          <div class="label">经营范围：</div>
          <div class="value scope">
            <div class="singleLine">
              {{ details.businessScope }}
            </div>
            <span
              v-if="details.businessScope && details.businessScope.length > 70"
              class="cursorP c409eff"
              @click="scopeShow = true"
            >
              更多
            </span>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- 经营范围 -->
    <customDialog v-model="scopeShow" title="经营范围" width="30%">
      <div class="mask">
        <div style="line-height: 30px">
          {{ details.businessScope }}
        </div>

        <div class="tac mt20">
          <el-button type="primary" @click="scopeShow = false">关闭</el-button>
        </div>
      </div>
    </customDialog>
  </div>
</template>

<script>
/* 企业详情-顶部公司信息 */

export default {
  props: ["details"],

  data() {
    return {
      scopeShow: false,
    };
  },

  methods: {},
};
</script>

<style lang="less" scoped>
.company_info {
  padding: 25px;
  background-color: #fff;
  margin-bottom: 20px;
  color: #333;
  font-size: 14px;

  .name_wrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 20px;

    .name {
      font-size: 20px;
      font-weight: bold;
    }
  }

  .value {
    flex: 1;
  }

  .scope {
    display: flex;
    align-items: center;
    .singleLine {
      flex: 1;
    }
  }
}
</style>
