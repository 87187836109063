<template>
  <div class="bussiness">
    <div class="public_title">工商信息</div>

    <div class="flex row">
      <div class="desc_item">
        <div class="label">企业法人</div>
        <div class="value">
          {{ details.legalPerson || "--" }}
        </div>
      </div>

      <div class="desc_item">
        <div class="label">经营状态</div>
        <div class="value">
          {{ details.state || "--" }}
        </div>
      </div>
    </div>

    <div class="flex row">
      <div class="desc_item">
        <div class="label">统一信用代码</div>
        <div class="value">
          {{ details.taxpayerId || "--" }}
        </div>
      </div>

      <div class="desc_item">
        <div class="label">注册资本</div>
        <div class="value">
          {{ details.regCost || "--" }}
        </div>
      </div>
    </div>

    <div class="flex row">
      <div class="desc_item">
        <div class="label">实缴资本</div>
        <div class="value">
          {{ details.paidupCapital || "--" }}
        </div>
      </div>

      <div class="desc_item">
        <div class="label">成立日期</div>
        <div class="value">
          {{ details.establishmentTime || "--" }}
        </div>
      </div>
    </div>

    <div class="flex row">
      <div class="desc_item">
        <div class="label">经营日期</div>
        <div class="value">
          {{ details.businessTerm || "--" }}
        </div>
      </div>

      <div class="desc_item">
        <div class="label">参保人数</div>
        <div class="value">
          {{ details.insured ? details.insured + "人" : "--" }}
        </div>
      </div>
    </div>

    <div class="flex row">
      <div class="desc_item">
        <div class="label">详细地址</div>
        <div class="value">
          {{ details.regAddress || "--" }}
        </div>
      </div>

      <div class="desc_item">
        <div class="label">曾用名</div>
        <div class="value">
          {{ details.nameUsed || "--" }}
        </div>
      </div>
    </div>

    <div class="desc_item row">
      <div class="label">经营范围</div>
      <div class="value">
        {{ details.businessScope || "--" }}
      </div>
    </div>
  </div>
</template>

<script>
/* 企业详情-工商信息 */

export default {
  props: ["details"],
};
</script>

<style lang="less" scoped>
.desc_item {
  flex: 1;
  display: flex;
  align-items: stretch;
  font-size: 14px;
  .label {
    padding: 15px;
    min-width: 200px;
    text-align: center;
    color: #666;
    display: flex;
    align-items: center;
    background-color: #f5faff;
  }

  .value {
    display: flex;
    align-items: center;
    padding: 15px;
    flex: 1;
    color: #666;
    border-right: 1px solid #ecf1ff;
    border-left: 1px solid #ecf1ff;
    line-height: 1.5;
  }
}
.row {
  border: 1px solid #ecf1ff;
  border-bottom: none;
  border-right: none;
  &:last-child {
    border-bottom: 1px solid #ecf1ff;
  }
}
</style>
