<template>
  <div class="certification">
    <div class="mb20">
      共
      <span class="highlight">{{ list.length }}</span>
      个资质
    </div>

    <!-- <div class="collapse_wrapper scrollbar"></div> -->
    <el-collapse v-model="activeNames">
      <el-collapse-item
        :name="index + 1"
        v-for="(item, index) in list"
        :key="item.id"
      >
        <template slot="title">
          <span class="index">{{ index + 1 }}</span>
          <span>
            {{ item.name }}
          </span>
        </template>

        <el-descriptions title="">
          <el-descriptions-item label="资质证书号">
            {{ item.code }}
          </el-descriptions-item>

          <el-descriptions-item label="发证日期">
            {{ item.startTime }}
          </el-descriptions-item>

          <el-descriptions-item label="发证有效期">
            {{ item.endTime }}
          </el-descriptions-item>

          <el-descriptions-item label="发证机关">
            {{ item.issuer }}
          </el-descriptions-item>
        </el-descriptions>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
/* 企业详情-资质列表 */

export default {
  props: ["list"],
  data() {
    return {
      activeNames: [1],
    };
  },
};
</script>

<style lang="less" scoped></style>
