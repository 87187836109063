// 企业详情模块
import request from '@/utils/request'// 引入设置好的axios

// post请求示例
/* export const addGoodsCate = (data) => {
    return request({
        url: '/goods/addcate',
        method: "post",
        data
    })
} */

// get请求示例
/* export const getCateList = (params) => {
    return request({
        url: '/goods/catelist',
        method: "get",
        params
    })
} */

// 通过公司ID获取公司信息
export const searchCompanyById = (params) => {
  return request({
    url: '/api/company/searchCompanyById',
    method: "get",
    params
  })
}