<template>
  <div class="personnel">
    <div class="mb20">
      共
      <span class="highlight">{{ total }}</span>
      名注册人员
    </div>

    <el-form ref="form" :model="form" label-width="70px" size="small">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="人员姓名">
            <el-input
              v-model.trim="form.name"
              placeholder="请输入人员完整姓名"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="证书编号">
            <el-input
              v-model.trim="form.regNo"
              placeholder="请输入人员证书号"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="人员证书" style="margin-bottom: 0px">
        <el-row
          class="mb22"
          v-for="(item, index) in personNoType"
          :key="'project' + index"
        >
          <el-col class="mr20" :span="11">
            <el-cascader
              style="width: 100%"
              v-model="item.value"
              :options="personNoTypeOptions"
              filterable
              :props="{
                value: 'allDesc',
                label: 'desc',
                children: 'children',
                emitPath: false,
              }"
              clearable
              placeholder="输入关键词查询"
            ></el-cascader>
          </el-col>

          <el-col :span="2" class="tac">
            <el-button
              v-if="index == 0"
              type="primary"
              icon="el-icon-plus"
              plain
              @click="addPersonNo"
            >
              增加
            </el-button>

            <el-button
              v-else
              @click="delPersonNo(index)"
              type="danger"
              icon="el-icon-delete"
              circle
              plain
            ></el-button>
          </el-col>
        </el-row>
      </el-form-item>

      <div class="tac">
        <el-button size="small" @click="reset" icon="el-icon-refresh-right">
          重置
        </el-button>

        <el-button
          size="small"
          type="primary"
          @click="query"
          icon="el-icon-search"
        >
          查询
        </el-button>
      </div>
    </el-form>

    <div v-loading="loading" class="collapse_wrapper scrollbar">
      <el-collapse v-model="activeNames" class="mt20">
        <el-collapse-item
          :name="index + 1"
          v-for="(item, index) in list"
          :key="'ps' + index"
        >
          <template slot="title">
            <span class="index">{{ index + 1 }}</span>
            <span>
              {{ getName(item) }}
            </span>
          </template>

          <el-descriptions title="">
            <el-descriptions-item label="身份证号">
              {{ item.idNo || "--" }}
            </el-descriptions-item>

            <el-descriptions-item label="注册类别">
              {{ item.regType || "--" }}
            </el-descriptions-item>

            <el-descriptions-item label="注册号（执业印章号）">
              {{ item.regNo3 || "--" }}
            </el-descriptions-item>

            <el-descriptions-item label="注册专业">
              {{ item.regMajor || "--" }}
            </el-descriptions-item>

            <el-descriptions-item label="有效期">
              {{ item.regTerm || "--" }}
            </el-descriptions-item>
          </el-descriptions>
        </el-collapse-item>
      </el-collapse>
    </div>

    <el-pagination
      class="pagination"
      @size-change="sizeChange"
      @current-change="currentChange"
      :current-page="form.pageNum"
      :page-sizes="[10, 20, 30, 40, 50, 100]"
      :page-size="form.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
/* 企业详情-注册人员 */
// 引入ajax函数
import { searchPersonByDto } from "@/api/itemizedQuery";
import { sourceTree } from "@/api/common";

export default {
  props: ["companyId"],

  // 数据
  data() {
    return {
      form: {
        name: "", // 人员姓名
        personNoType: "", // 人员证书
        regNo: "", // 人员证书编号
        companyId: "", // 公司ID
        pageNum: 1,
        pageSize: 20,
      },

      personNoTypeOptions: [], // 人员证书选项数据
      personNoType: [{ value: "" }], // 人员证书数据

      list: [], // 列表
      total: 0, // 总数
      loading: false, // 加载状态
      activeNames: [1],
    };
  },

  watch: {
    companyId: {
      handler(newVal) {
        if (newVal) {
          this.__init__();
        }
      },
      deep: true,
      immediate: true,
    },
  },

  // 方法
  methods: {
    /* 获取数据 */
    // 获取列表数据
    async getData() {
      this.loading = true;
      const personNoType = this.personNoType
        .filter((v) => v.value != "")
        .map((v) => v.value)
        .join(",");

      const params = {
        ...this.form,
        personNoType,
        companyId: this.companyId,
      };

      const res = await searchPersonByDto(params);
      this.list = res.records;
      this.total = res.total;
      this.loading = false;
    },
    // 获取树形数据
    async getOptions() {
      const personNoTypeOptions = await sourceTree({
        source: "personnelNoType",
      });
      this.personNoTypeOptions = personNoTypeOptions;
    },

    /* 操作 */
    getName(item) {
      if (item.name && item.regType && item.regMajor) {
        return `${item.name} / ${item.regType} / ${item.regMajor}`;
      } else if (item.name && item.regType) {
        return `${item.name} / ${item.regType}`;
      } else if (item.name && item.regMajor) {
        return `${item.name} / ${item.regMajor}`;
      } else if (item.name) {
        return `${item.name}`;
      } else {
        return `--`;
      }
    },
    // 添加人员证书
    addPersonNo() {
      if (this.personNoType.length < 5) {
        this.personNoType.push({
          value: "",
        });
      } else {
        this.$message({ message: "最多添加5条筛选条件~", type: "warning" });
      }
    },
    // 删除人员证书
    delPersonNo(index) {
      this.personNoType.splice(index, 1);
    },
    // 当前显示页数改变时
    sizeChange(val) {
      this.form.pageSize = val;
      this.getData();
    },
    // 当前页改变时
    currentChange(val) {
      this.form.pageNum = val;
      this.getData();
    },
    // 重置
    reset() {
      this.form = {
        name: "", // 人员姓名
        personNoType: "", // 人员证书
        regNo: "", // 人员证书编号
        companyId: "", // 公司ID
        pageNum: 1,
        pageSize: 20,
      };
      this.personNoType = [{ value: "" }];
    },
    // 查询
    async query() {
      this.form.pageNum = 1;
      this.getData();
    },

    /* 初始化 */
    __init__() {
      this.getData();
      this.getOptions();
    },
  },
};
</script>

<style lang="less" scoped></style>
