<template>
  <div class="performance">
    <div class="mb20">
      共
      <span class="highlight">{{ total }}</span>
      条业绩信息
    </div>

    <el-form ref="form" :model="form" label-width="82px" size="small">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="项目地区">
            <el-cascader
              v-model="form.location"
              :options="areaOptions"
              filterable
              :props="{ value: 'value', label: 'name', children: 'city' }"
              clearable
              style="width: 100%"
              placeholder="输入关键词搜索"
            ></el-cascader>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="中标时间">
            <el-date-picker
              style="width: 100%"
              v-model="time"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="项目经理">
            <el-input
              v-model.trim="form.manager"
              placeholder="请输入项目经理姓名"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="项目名称">
            <el-input
              v-model.trim="form.name"
              placeholder="请输入项目名称关键词"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <div v-if="showmore">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="技术负责人">
              <el-input
                v-model.trim="form.technician"
                placeholder="请输入技术负责人姓名"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="专职安全员">
              <el-input
                v-model.trim="form.secure"
                placeholder="请输入专职安全员姓名"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="项目类型">
              <el-select
                style="width: 100%"
                v-model="form.projectType"
                placeholder="请选择项目类型"
                clearable
              >
                <el-option
                  v-for="item in projectTypeOptions"
                  :key="item.name"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="项目金额">
              <div class="flex alc">
                <el-input
                  v-model.number.trim="form.amountMin"
                  placeholder="请输入最小值"
                  clearable
                >
                  <template slot="append">万</template>
                </el-input>

                <span class="ml10 mr10">至</span>

                <el-input
                  v-model.number.trim="form.amountMax"
                  placeholder="请输入最大值"
                  clearable
                >
                  <template slot="append">万</template>
                </el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </div>

      <div class="tac">
        <el-button size="small" @click="reset" icon="el-icon-refresh-right">
          重置
        </el-button>

        <el-button
          size="small"
          type="primary"
          @click="query"
          icon="el-icon-search"
        >
          查询
        </el-button>

        <el-button size="small" @click="showmore = !showmore">
          {{ this.showmore ? "收起" : "更多" }}
        </el-button>
      </div>
    </el-form>

    <el-table
      v-loading="loading"
      :data="list"
      border
      style="width: 100%; margin-top: 20px"
      ref="performance"
    >
      <el-table-column type="index" width="50"> </el-table-column>

      <el-table-column
        sortable
        prop="name"
        min-width="100"
        :show-overflow-tooltip="true"
        label="业绩名称"
      >
        <template slot-scope="scope">
          <div class="link" @click="toDetail(scope.row.id)">
            {{ scope.row.name }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        sortable
        prop="place"
        min-width="100"
        :show-overflow-tooltip="true"
        label="项目地区"
      ></el-table-column>

      <el-table-column
        sortable
        prop="money"
        min-width="100"
        :show-overflow-tooltip="true"
        label="项目金额"
      ></el-table-column>

      <el-table-column
        sortable
        prop="manager"
        min-width="100"
        :show-overflow-tooltip="true"
        label="项目经理"
      ></el-table-column>

      <el-table-column
        sortable
        prop="time"
        min-width="100"
        :show-overflow-tooltip="true"
        label="项目时间"
      ></el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      class="pagination"
      @size-change="sizeChange"
      @current-change="currentChange"
      :current-page="form.pageNum"
      :page-sizes="[20, 50, 100, 150, 200]"
      :page-size="form.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
/* 企业详情-企业业绩 */
// 引入ajax函数
import { searchResult } from "@/api/itemizedQuery";
import { getDict } from "@/api/common";
// 引入区域json
import area from "@/utils/area";

export default {
  props: ["companyId"],

  // 数据
  data() {
    return {
      form: {
        location: [], // 地区,
        amountMin: "", // 金额-最小
        amountMax: "", // 金额-最大
        name: "", // 项目名称
        manager: "", // 项目经理
        projectType: "", // 项目类型
        timeStart: "", // 中标时间-开始
        timeEnd: "", // 中标时间-结束
        technician: "", // 技术负责人
        secure: "", // 专职安全员
        pageNum: 1,
        pageSize: 20,
        winnerId: "", // 公司Id
      },

      time: [], // 时间
      projectTypeOptions: [], // 项目类型

      // 时间选择器快捷选项
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近五年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 5);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },

      list: [], // 列表
      total: 0, // 总数
      loading: false, // 加载状态
      showmore: false, // 更多查询项
    };
  },

  watch: {
    companyId: {
      handler(newVal) {
        if (newVal) {
          this.__init__();
        }
      },
      deep: true,
      immediate: true,
    },
  },

  // 方法
  methods: {
    /* 获取数据 */
    // 获取列表数据
    async getData() {
      this.loading = true;

      const location = this.form.location.filter((v) => v != "").join("-");
      let timeStart = "";
      let timeEnd = "";
      if (this.time.length > 0) {
        timeStart = this.time[0];
        timeEnd = this.time[1];
      }

      const params = {
        ...this.form,
        location,
        timeStart,
        timeEnd,
        winnerId: this.companyId,
      };

      const res = await searchResult(params);
      res.records.forEach((v) => {
        if (v.amtUnit == "%" || !v.amtUnit) {
          v.amount = "-";
          v.amtUnit = "-";
        } else if (v.amtUnit == "元") {
          v.amount = (v.amount / 10000).toFixed(2);
          v.amtUnit = "万";
        } else {
          v.amount = v.amount.toFixed(2);
          v.amtUnit = "万";
        }
        v["money"] = v.amount + v.amtUnit;

        if (v.time && v.time.includes(" ")) {
          v.time = v.time.split(" ")[0];
        }
        if (v.manager == "-1" || !v.manager) {
          v.manager = "--";
        }
      });
      this.list = res.records;
      this.total = res.total;
      this.loading = false;
    },
    // 获取下拉选项数据
    async getOptions() {
      // 项目类型
      const projectTypeOptions = await getDict({ source: "projectType" });
      this.projectTypeOptions = projectTypeOptions;
    },

    /* 操作 */
    // 跳转详情
    toDetail(id) {
      this.$router.push({ name: "performanceDetail", params: { id } });
    },
    // 当前显示页数改变时
    sizeChange(val) {
      this.form.pageSize = val;
      this.getData();
    },
    // 当前页改变时
    currentChange(val) {
      this.form.pageNum = val;
      this.getData();
    },
    // 重置
    reset() {
      this.form = {
        location: [], // 地区,
        amountMin: "", // 金额-最小
        amountMax: "", // 金额-最大
        name: "", // 项目名称
        manager: "", // 项目经理
        projectType: "", // 项目类型
        timeStart: "", // 中标时间-开始
        timeEnd: "", // 中标时间-结束
        technician: "", // 技术负责人
        secure: "", // 专职安全员
        pageNum: 1,
        pageSize: 20,
        winnerId: "", // 公司Id
      };
      this.time = [];
    },
    // 查询
    async query() {
      this.form.pageNum = 1;
      this.getData();
    },

    /* 初始化 */
    __init__() {
      this.areaOptions = area;
      this.getData();
      this.getOptions();
    },
  },
};
</script>

<style lang="less" scoped></style>
